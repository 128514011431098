.footer {
  background-color: #72b678;
  color: #fff;
  font-size: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8rem;
  a,span {
    color: #fff;
  }
}
