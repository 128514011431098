.info-box {
  display: flex;
  flex-wrap: wrap;
}

.card {
  border: 1px solid #ccc;
  // border-bottom: 3px solid var(--light-blue);
  padding: 5px;
  background-color: #f5f6fa;
}

.card1 {
  border: 3px solid #00d084;
}
.card2 {
  border: 3px solid #00a66a;
}
.card3 {
  border: 3px solid #007d4f;
}
