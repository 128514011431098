.card {
  width: 100%;
  max-width: 500px;
  padding: 1rem;
  border: 1px solid var(--green);
  margin-bottom: 5px;
}
h3 {
  font-weight: 200;
  color: var(--color-success);
}
b {
  color:#72b678;
}

.text {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    color: var(--color-success);
  }
}
